<template>
  <div class="product-categories">
    <div v-if="isEditing" class="admin-popup">
      <span class="material-icons close-icon" @click="resetState"> close </span>
      <h4>
        Update labels for:
        <b>{{ targetCategoryId }}</b>
      </h4>

      <input
        v-for="lang in enabledLanguages"
        :key="'edit-field-' + lang.code"
        :placeholder="lang.name + ' label...'"
        class="input"
        type="text"
        :ref="'edit-' + lang.code"
        :value="labels[lang.code]"
      />

      <button @click="saveChanges()">Save category</button>
    </div>
    <div v-if="isCreating" class="admin-popup">
      <span class="material-icons close-icon" @click="resetState"> close </span>
      <h4>Add product category</h4>
      <input
        v-model="newCategoryId"
        type="text"
        class="input"
        placeholder="Visma ID"
      />
      <input
        v-for="lang in enabledLanguages"
        :key="'create-field-' + lang.code"
        :placeholder="lang.name + ' label...'"
        class="input"
        type="text"
        :ref="'edit-' + lang.code"
        :value="labels[lang.code]"
      />
      <button @click="saveChanges()">Create category</button>
    </div>
    <h3>Product categories</h3>
    <p>Managed manually. Not connected to VismaAPI.</p>
    <div class="admin-table product-categories-table">
      <div class="table-header">
        <span class="label id">ID</span>

        <span
          class="label lang"
          v-for="lang in enabledLanguages"
          :key="'label-' + lang.code"
          ><img :src="require('../../assets/flags/' + lang.code + '.png')"
        /></span>
      </div>

      <div
        v-for="(category, id) in productCategories.en"
        :key="'category-id-' + id"
      >
        <span class="id">
          {{ id }}
        </span>

        <span
          class="lang"
          v-for="lang in enabledLanguages"
          :key="'option-' + lang.code"
          >{{ productCategories[lang.code][id] }}</span
        >

        <div class="controlls" v-if="userRole.slug === 'superAdmin'">
          <div class="edit" @click="editCategory(id)">
            <span class="material-icons edit-icon icon">edit</span>
          </div>
          <div class="delete" @click="deleteCategory(id)">
            <span class="material-icons delete-icon icon"> delete </span>
          </div>
        </div>
      </div>
    </div>
    <button class="add-row-button" @click="createCategory()">
      Add product category
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ProductCategories",
  data() {
    return {
      isEditing: false,
      isCreating: false,
      newCategoryId: "",
      targetCategoryId: "",
      labels: {},
    };
  },
  computed: {
    ...mapGetters("settings", ["productCategories", "userRole"]),
    ...mapGetters("languages", ["enabledLanguages"]),
  },
  created() {
    console.log(this.enabledLanguages);
    this.enabledLanguages.map((lang) => {
      this.labels[lang.code] = "";
    });
  },
  methods: {
    createCategory: function () {
      this.isCreating = true;
    },
    editCategory: function (id) {
      //Setup labels
      let labels = {};
      this.enabledLanguages.forEach((lang) => {
        labels[lang.code] = this.productCategories[lang.code][id];
      });

      this.labels = labels;
      this.isCreating = false;
      this.targetCategoryId = id;
      this.isEditing = true;
    },
    resetState: function () {
      this.isCreating = false;
      this.isEditing = false;
      this.newCategoryId = "";
      this.targetCategoryId = "";
      this.labels = {};
      this.enabledLanguages.map((lang) => {
        this.labels[lang.code] = "";
      });
    },
    saveChanges: async function () {
      let categoryId;

      if (this.targetCategoryId !== "") {
        categoryId = this.targetCategoryId;
      } else {
        categoryId = this.newCategoryId;
      }

      console.log(categoryId);

      let labels = {};
      this.enabledLanguages.forEach((lang) => {
        labels[lang.code] = this.$refs["edit-" + lang.code][0].value;
      });

      console.log(labels);
      this.$store
        .dispatch("settings/updateProductCategory", {
          id: categoryId,
          labels: labels,
        })
        .then(() => {
          this.resetState();
        });
    },
    deleteCategory: function (id) {
      let confirm = window.confirm("Delete category with id: " + id + "?");
      if (confirm) {
        this.$store.dispatch("settings/deleteProductCategory", {
          id: id,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.product-categories-table {
  margin-bottom: 10px;

  .id {
    width: 50px;
  }

  .lang {
    width: 150px;

    img {
      width: 24px;
    }
  }
}

.controlls {
  display: flex;
  gap: 5px;
  margin-left: 10px;
  justify-content: flex-end;
  align-items: center;
}

.delete,
.edit {
  display: flex;
  gap: 5px;
  align-self: center;
  &:hover {
    cursor: pointer;
  }

  .icon {
    font-size: 16px;
  }
}
</style>
