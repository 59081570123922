<template>
  <div class="product-categories">
    <div v-if="isEditing" class="admin-popup">
      <span class="material-icons close-icon" @click="resetState"> close </span>
      <h4>
        Update labels for:
        <b>{{ editingCategoryId }}</b>
      </h4>

      <input placeholder="Name..." class="input" type="text" v-model="name" />

      <div class="shortlists-products-search">
        <input type="search" placeholder="Search product..." v-model="search" />
        <div class="shortlist-search-container">
          <div v-for="product in getProductsBySearch(search)" :key="product.id">
            <span class="id">{{ product.vismaId }}</span>
            <span class="stock">{{ product.stock }}{{ product.unit }}</span>
            <span class="name">{{ product.name }}</span>
            <span
              class="action remove"
              v-if="products.includes(product.firebaseId)"
              @click="productRemove(product.firebaseId)"
              >Remove</span
            >
            <span
              v-else
              class="action add"
              @click="productAdd(product.firebaseId)"
              >Add</span
            >
          </div>
        </div>
      </div>

      <button @click="saveChanges()">Save category</button>
    </div>
    <div v-if="isCreating" class="admin-popup">
      <span class="material-icons close-icon" @click="resetState"> close </span>
      <h4>Add extra product category</h4>
      <input placeholder="Name..." class="input" type="text" v-model="name" />

      <div class="shortlists-products-search">
        <input type="search" placeholder="Search product..." v-model="search" />
        <div class="shortlist-search-container">
          <div v-for="product in getProductsBySearch(search)" :key="product.id">
            <span class="id">{{ product.vismaId }}</span>
            <span class="stock">{{ product.stock }}{{ product.unit }}</span>
            <span class="name">{{ product.name }}</span>
            <span
              class="action remove"
              v-if="products.includes(product.firebaseId)"
              @click="productRemove(product.firebaseId)"
              >Remove</span
            >
            <span
              v-else
              class="action add"
              @click="productAdd(product.firebaseId)"
              >Add</span
            >
          </div>
        </div>
      </div>
      <button @click="saveChanges()">Create category</button>
    </div>
    <h3>Extra product categories</h3>
    <p>These categories do not exist in Visma.</p>
    <div class="admin-table product-categories-table">
      <div class="table-header">
        <span class="label lang">Name</span>
        <span class="label products">Produkter</span>
      </div>

      <div
        v-for="(category, id) in extraProductCategories"
        :key="'category-id-' + id"
      >
        <span class="lang">{{ extraProductCategories[id].name }}</span>
        <span class="products">
          <span
            v-for="productId in extraProductCategories[id].products"
            :key="id + productId"
          >
            {{ getProductByFirebaseId(productId).name }}
            <span
              v-if="
                productId !==
                extraProductCategories[id].products[
                  extraProductCategories[id].products.length - 1
                ]
              "
              >,
            </span>
          </span>
        </span>

        <div class="controlls">
          <div class="edit" @click="editCategory(id)">
            <span class="material-icons edit-icon icon">edit</span>
          </div>
          <div class="delete" @click="deleteCategory(id)">
            <span class="material-icons delete-icon icon"> delete </span>
          </div>
        </div>
      </div>
    </div>
    <button class="add-row-button" @click="createCategory()">
      Add product category
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { nanoid } from "nanoid";

export default {
  name: "ProductCategories",
  data() {
    return {
      isEditing: false,
      isCreating: false,
      editingCategoryId: "",
      labels: {},
      search: "",
      products: [],
      name: "",
    };
  },
  computed: {
    ...mapGetters("settings", ["extraProductCategories", "userRole"]),
    ...mapGetters("products", [
      "getProductsBySearch",
      "getProductByFirebaseId",
    ]),
  },
  created() {},
  methods: {
    createCategory: function () {
      this.isCreating = true;
    },
    editCategory: function (id) {
      this.products = JSON.parse(
        JSON.stringify(this.extraProductCategories[id].products)
      );
      this.name = JSON.parse(
        JSON.stringify(this.extraProductCategories[id].name)
      );

      this.isCreating = false;
      this.isEditing = true;
      this.editingCategoryId = id;
    },
    resetState: function () {
      this.isCreating = false;
      this.isEditing = false;
      this.products = [];
      this.search = "";
      this.editingCategoryId = "";
      this.name = "";
    },
    saveChanges: async function () {
      let categoryId;

      if (this.isCreating) {
        categoryId = nanoid();
      } else {
        categoryId = this.editingCategoryId;
      }

      console.log(categoryId);
      console.log(this.products);
      this.$store
        .dispatch("settings/updateExtraProductCategory", {
          id: categoryId,
          name: this.name,
          products: this.products,
        })
        .then(() => {
          this.resetState();
        });
    },
    deleteCategory: function (id) {
      let confirm = window.confirm("Delete category with id: " + id + "?");
      if (confirm) {
        this.$store.dispatch("settings/deleteExtraProductCategory", {
          id: id,
        });
      }
    },
    productAdd: function (id) {
      this.products.push(id);
    },
    productRemove: function (id) {
      this.products = this.products.filter((productId) => productId !== id);
    },
  },
};
</script>

<style lang="scss" scoped>
.product-categories-table {
  margin-bottom: 10px;

  .id {
    width: 50px;
  }

  .lang {
    width: 150px;
    min-width: 150px;

    img {
      width: 24px;
    }
  }

  .products {
    display: flex;
    flex-wrap: wrap;
  }
}

.controlls {
  display: flex;
  gap: 5px;
  margin-left: 10px;
  justify-content: flex-end;
  align-items: center;
}

.delete,
.edit {
  display: flex;
  gap: 5px;
  align-self: center;
  &:hover {
    cursor: pointer;
  }

  .icon {
    font-size: 16px;
  }
}

.shortlist-search-container {
  max-height: 300px;
  width: 550px;
  margin-bottom: 10px;
}
</style>
